<template>
    <chat
        legend='עכשיו שסיימת את ההרשמה למערכת, אפשר למלא את שאלון הקבלה.
            אף אחת מהשאלות היא לא חובה - אם אין לך תשובה לשאלה, אפשר פשוט לדלג עליה.'
        :initiateSubmit='initiateSubmit'
    >
    </chat>
</template>

<script>
import Chat from '../../Chat/index.vue';
import chatModule from '../../Chat/module';
import store from '@/store';

import { createNamespacedHelpers } from 'vuex';
import { defineComponent } from '@vue/runtime-core';
const { mapState, mapMutations } = createNamespacedHelpers('chat');

// NEEDS WORK

export default defineComponent({
    beforeRouteEnter(_, _2, next){
        const { type, intaken } = store.state.user;
        if(type !== 'patient' || intaken)
            next('/');
        else
            next();
    },
    async beforeMount(){
        this.$store.registerModule('chat', chatModule);
        const fetched = await this.$request('/patient/intake');
        this.$store.commit('chat/assignFetched', fetched.body);
    },
    components: { Chat },
    computed: mapState(['unique', 'time', 'questions']),
    methods: {
        ...mapMutations(['setSubmitting']),
        async initiateSubmit(){
            this.setSubmitting();
            const { unique, questions } = this;

            let answers = {};
            for(let q of questions) if(q.show) answers[q.code] = q.val;
            const startTime = new Date().getTime();
            try {
                const response = await this.sendRequest('/patient/intake', 'post', { answers, unique, time: 0 });
                setTimeout(() => {
                    if(response.body.success){
                        this.$store.commit('setIntaken', true);
                        this.$router.push('/');
                        this.$store.commit('setToast', { text: 'התשובות שלך נשמרו בהצלחה' });
                    }
                }, 800 - (new Date().getTime() - startTime));
            } catch(error) {
                console.error(error);
                this.setSubmitting();
            }
		},
    }
});
</script>
